import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color } from '../../styles/theme';
import { WithUnderline } from '../../styles/link';
import { Wrapper, Container, Inner } from '../../styles/articles-nav';

import arrow from '../../images/arrow-white.svg';

const Text = styled.p`
  margin-bottom: 35px;
  font-weight: bold;
  line-height: 1.5;
  color: ${color('white')};
  text-transform: uppercase;
  
  @media (min-width: 455px) {
    margin-bottom: 0;
  }
`;

const Link = styled(WithUnderline)`
  font-weight: bold;
  font-size: 24px;
  line-height: 1.25;
  color: ${color('white')};
  text-decoration: none;
  
  img {
    margin-left: 10px;
  }
`;

const MoreArticles = ({ text, title, link }) => (
  <Wrapper css={{
    paddingTop: '55px',
    paddingBottom: '55px',
  }}
  >
    <Container>
      <Inner css={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Text>
          {text}
        </Text>
        <Link to={link} underline="white">
          <span>{title}</span>
          <img src={arrow} alt="" />
        </Link>
      </Inner>
    </Container>
  </Wrapper>
);

MoreArticles.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
};

MoreArticles.defaultProps = {
  link: '/case-studies/',
  text: 'Looking for more case studies?',
  title: 'See all',
};

export default MoreArticles;
