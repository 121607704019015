import React from 'react';
import styled from 'styled-components';
import Form from './form/Form';
import * as FormHeader from './form/FormHeader';
import { Wrapper, Container } from '../styles/contact';
import { Divider } from '../styles/elements';
import { gray, color, themeColor } from '../styles/theme';

const FormWrapper = styled(Wrapper)`
  @media (min-width: 1024px) {
    min-height: 100%;
  }
`;

const FormContainer = styled(Container)`
  display: flex;
  justify-content: center;
  padding: 40px 15px;
  
  @media(min-width: 768px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  
  @media (min-width: 1440px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  
  @media (min-width: 1920px) {
    padding-top: 120px;
    padding-bottom: 120px;
  }
`;

const ContactCaseStudy = () => (
  <FormWrapper>
    <FormContainer css={{
      backgroundColor: gray(100),
    }}
    >
      <FormHeader.Container css={{ marginBottom: 0 }}>
        <FormHeader.Title css={{
          marginBottom: '15px',
          color: color('black'),
        }}
        >
          Call us
        </FormHeader.Title>
        <Divider css={{ marginTop: '0' }} />
        <FormHeader.Phone
          as="a"
          href="tel:3602094534‬"
          css={{
            color: color('black'),
          }}
        >
          (360) 209-4534‬
        </FormHeader.Phone>
        <FormHeader.Text css={{
          maxWidth: '550px',
          color: gray(500),
        }}
        >
          Call us. We can answer
          questions and help you think through your idea.
        </FormHeader.Text>
      </FormHeader.Container>
    </FormContainer>
    <FormContainer css={{
      backgroundColor: themeColor('primary'),
    }}
    >
      <div css={{ width: '100%', maxWidth: '550px' }}>
        <FormHeader.Title>
          Email us
        </FormHeader.Title>
        <Form />
      </div>
    </FormContainer>
  </FormWrapper>
);

export default ContactCaseStudy;
