import React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { themeColor, gray, color } from '../../styles/theme';
import { WithUnderline } from '../../styles/link';
import { Divider } from '../../styles/elements';
import arrow from '../../images/arrow.svg';

const Article = styled.article`
  padding: 100px 15px;
  background-color: ${({ reverse }) => reverse && gray(100)};
  overflow: hidden;
`;

const Container = styled.div`
  display: flex;
  flex-direction: ${({ reverse }) => reverse ? 'row-reverse' : 'row'};
  align-items: center;
  flex-wrap: wrap-reverse;
  margin: 0 auto;
  max-width: 920px;
  
  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }
`;

const Content = styled.div`
  flex-shrink: 0;
  width: 100%;
  max-width: 350px;
`;

const ImageWrapper = styled.div.attrs((props) => ({
  offset: props.reverse ? '-125px' : '125px',
  margin: props.reverse ? 'margin-right: 35px' : 'margin-left: 35px',
}))`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
  width: 100%;
  max-width: 670px;
  height: 220px;
  background-color: ${color('turquoise-blue-100')};
  transition: all .4s cubic-bezier(.02, .01, .47, 1);
  
  ${({ image }) => image && css`
    background-image: url(${({ image }) => image && image});
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
  `};
  
  @media (min-width: 768px) {
    ${(props) => props.margin};
    margin-bottom: 0;
    height: 320px;
  }
  
  @media (min-width: 1024px) {
    flex-shrink: 0;
    height: 420px;
    transform: translateX(${(props) => props.offset});
  }
  
  &:hover {
    transform: translateY(-20px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, .2);
    
    @media (min-width: 1024px) {
      transform: translate(${(props) => props.offset}, -20px);
    }

    .logo {
      transform: translate(-50%, -50%) scale(1.1);
    }
  }
`;

const Title = styled.h3`
  .article-preview & {
    margin-bottom: 20px;
    font-size: 33px;
    line-height: 1.1;
    color: ${themeColor('primary')};
    
    a {
      font-size: inherit;
      line-height: inherit;
      color: inherit;
      text-decoration: none;
      transition: color .3s ease-in-out;
      
      &:hover {
        color: ${color('turquoise-blue')};
      }
    }
  }
`;

const Subtitle = styled.h4`
  .article-preview & {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 1.5;
    text-transform: uppercase;
    color: ${gray(500)};
    
    .services-template & {
      display: none;
    }
  }
`;

const Excerpt = styled.div`
  .article-preview & {
    line-height: 1.5;
    color: ${themeColor('primary')};
    
    p {
      margin-bottom: 35px;
      line-height: inherit;
    }
  }
`;

const ArticleLink = styled(WithUnderline)`
  .article-preview & {
    position: relative;
    padding-bottom: 5px;
    font-weight: bold;
    font-size: 24px;
    color: ${color('turquoise-blue')};
    text-decoration: none;
    
    &:hover {
      text-decoration: none;
    }
    
    img {
      position: relative;
      top: .125em;
      margin-left: 10px;
      height: 1em;
      width: 1em;
      transition: all .2s ease-in-out;
    }
  }
`;

const LinkStyled = styled(Link)`
  width: 100%;
  height: 100%;
`;

const Logo = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  width: 80px;
  height: auto;
  transition: all .4s cubic-bezier(.02, .01, .47, 1);
  
  @media (min-width: 768px) {
    width: 120px;
  }
`;

const ArticlePreview = ({ data, reverse }) => {
  const {
    frontmatter: {
      title,
      date,
      name,
      label,
      logo,
      excerpt,
      preview_image: previewImage,
    },
    fields,
  } = data;

  return (
    <Article
      className="article-preview"
      data-target={fields.slug.replace('/', '')}
      reverse={reverse}
    >
      <Container reverse={reverse}>
        <Content>
          <Title>
            <Link to={fields.slug}>
              {name || title}
            </Link>
          </Title>
          <Subtitle>{label || date}</Subtitle>
          <Divider />
          <Excerpt>
            <p>{excerpt}</p>
          </Excerpt>
          <ArticleLink to={fields.slug}>
            <span>Take a look</span>
            <img src={arrow} alt="" />
          </ArticleLink>
        </Content>
        <ImageWrapper reverse={reverse} image={previewImage && previewImage.publicURL}>
          <LinkStyled to={fields.slug}>
            <Logo className="logo" src={logo && logo.publicURL} alt={name} />
          </LinkStyled>
        </ImageWrapper>
      </Container>
    </Article>
  );
};

export default ArticlePreview;
