import styled from 'styled-components';
import { color, themeColor } from './theme';

const Article = styled.section`
  margin: 0 auto;
  padding: 75px 15px 50px;
  max-width: 730px;
  
  h2, h3, h4, h5, h6 {
    margin-bottom: 20px;
    line-height: 1.1;
    color: ${themeColor('primary')};
  }
  
  h3, h4, h5, h6 {
    letter-spacing: -0.3px;
  }
  
  h2 {
    font-size: 33px;
  }
  
  h3 {
    font-size: 24px;
  }
  
  h4 {
    font-size: 20px;
  }
  
  h5 {
    font-size: 18px;
  }
  
  h6 {
    font-size: 16px;
  }
  
  p {
    line-height: 2;
    color: ${themeColor('primary')};
    
    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }
  
  ul, ol {
    margin-bottom: 40px;
    line-height: 2;
    color: ${themeColor('primary')};
  }
  
  a {
    font-weight: bold;
    color: ${color('turquoise-blue')};
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
  
  th,
  td {
    border-bottom: 0;
  }
  
  td {
    vertical-align: top;
  }
  
  .gatsby-resp-image-wrapper {
    margin-top: 80px;
    margin-bottom: 80px;
    
    @media (min-width: 1024px) {
      width: calc(100vw - 300px);
      max-width: 1148px !important;
      transform: translateX(calc((100vw - 1000px) / -2));
    }
    
    @media (min-width: 1440px) {
      width: 1148px;
      transform: translateX(calc((1148px - 700px) / -2));
    }
  }
  
  .caption {
    margin-top: -60px;
    display: block;
    font-style: italic;
    font-size: 14px;
    line-height: 1.5;
  }
  
  [data-inner] {
    margin-bottom: 50px;
    
    @media (min-width: 1024px) {
      margin-left: calc((100vw - 100%) / -2);
      width: 100vw;
    }
    
    @media (min-width: 1600px) {
      margin-left: calc((1440px - 100%) / -2);
      max-width: 1440px;
    }
  }
`;

export { Article };
