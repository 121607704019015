import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import ArticleHeader from '../components/articles/ArticleHeader';
import ContactCaseStudy from '../components/ContactCaseStudy';
import Layout from '../components/layout/layout';
import MoreArticles from '../components/articles/MoreArticles';
import SEO from '../components/seo';

import { Article } from '../styles/article';
import ArticlePreview from '../components/articles/ArticlePreview';

const Hero = styled.div`
  min-height: 350px;
  background-image: url(${({ image }) => image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  
  @media (min-width: 768px) {
    min-height: 650px;
  }
  
  @media (min-width: 1920px) {
    min-height: 850px;
  }
`;

const Page = ({ data }) => {
  const { frontmatter, html, fields } = data.markdownRemark;
  const {
    title,
    excerpt,
    featured_image: featuredImage,
    preview_image: previewImage,
  } = frontmatter;
  const featured = featuredImage || previewImage;

  function RenderCaseStudy({ node }) {
    if (fields.case_study.fields.slug !== '') {
      return ReactDOM.createPortal(
        <ArticlePreview data={fields.case_study} reverse />,
        node,
      );
    }

    return null;
  }

  const [node, setNode] = useState(null);

  useEffect(() => {
    setNode(document.querySelector('[data-inner]'));
  }, []);

  return (
    <Layout>
      <SEO title={title} description={excerpt} />
      <ArticleHeader {...frontmatter} />
      { featured && (
        <Hero image={featured.publicURL} />
      ) }
      <Article dangerouslySetInnerHTML={{ __html: html }} />
      { node ? <RenderCaseStudy node={node} /> : null }
      <ContactCaseStudy />
      <MoreArticles text="Check our latest case studies" />
    </Layout>
  );
};

export default Page;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        case_study {
          fields {
            slug
          }
          frontmatter {
            name
            label
            title
            excerpt
            preview_image {
              publicURL
            }
            logo {
              publicURL
            }
          }
        }
      }
      frontmatter {
        title
        date(formatString: "DD MMMM, YYYY")
        excerpt
        preview_image {
          publicURL
        }
        featured_image {
          publicURL
        }
      }
      html
    }
  }
`;
