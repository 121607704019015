import styled from 'styled-components';
import { color } from './theme';

const Divider = styled.hr.attrs(
  ({ margin }) => ({
    margin: margin || '20px 0 30px',
  }),
)`
  margin: ${({ margin }) => margin};
  width: 80px;
  height: 7px;
  text-align: left;
  background-color: ${({ bgColor }) => bgColor || color('turquoise-blue-200')};
`;

export { Divider };
