import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import arrow from '../../images/arrow-white.svg';
import logo from '../../images/logo.svg';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  z-index: 15;
`;

const Arrow = styled.img`
  margin-right: 25px;
  transform: rotate(-180deg);
  vertical-align: middle;
  transition: transform .2s ease-in-out;
`;

const Logo = styled.img`
  height: 36px;
  vertical-align: middle;
  transition: all .3s;
  
  @media (min-width: 768px) {
    height: 46px;
  }
  
  &:hover {
    opacity: .5;
  }
`;

const Back = styled.button`
  display: inline-block;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    .arrow {
      transform: translateX(20%) rotate(-180deg);
    }
  }
`;

function goBack() {
  window.history.back();
}

const NavHero = () => (
  <Wrapper>
    <Back onClick={goBack}>
      <span className="visually-hidden">Go Back</span>
      <Arrow className="arrow" src={arrow} alt="" />
    </Back>
    <Link to="/">
      <span className="visually-hidden">Home</span>
      <Logo src={logo} alt="" />
    </Link>
  </Wrapper>
);

export default NavHero;
