import React from 'react';
import styled from 'styled-components';
import hex2rgba from 'hex2rgba';
import NavHero from '../navigation/NavHero';
import { MainNav } from '../navigation/MainNav';
import { color, themeColor } from '../../styles/theme';
import { Header } from '../Header';

const HeaderWrapper = styled.div`
  position: fixed;
  padding: 20px 0 35px;
  width: 100%;
  height: auto;
  transition: all .8s cubic-bezier(0, 0, 0.2, 1);
  z-index: 100;
  will-change: height;
  
  @media (min-width: 768px) {
    overflow: hidden;
    padding-bottom: 60px;
  }
  
  @media (min-width: 1440px) {
    padding-bottom: 75px;
  }
  
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${themeColor('primary')};
  }
  
  &.collapsed {
    height: 76px !important;
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
  }
`;

const Container = styled.div`
  position: relative;
  z-index: 5;
  margin: 0 auto;
  max-width: 1150px;
`;

const Logo = styled.img`
  display: none;
  position: absolute;
  left: 50%;
  z-index: 15;
  transform: translateX(-50%);
  width: auto;
  height: 40px;
  
  @media (min-width: 768px) {
    height: 55px;
  }
  
  @media (min-width: 769px) and (max-width: 1110px) {
    display: none !important;
  }
  
  .collapsed & {
    display: block;
  }
`;

const Heading = styled.div`
  padding: 150px 15px 0;
  height: 100%;
  transform: translateX(0);
  transition: transform .8s cubic-bezier(0, 0, 0.2, 1), opacity .8s cubic-bezier(0, 0, 0.2, 1) .4s;

  @media (max-width: 767.98px) {
    &.collapsed {
      height: 0;
      opacity: 0;
      overflow: hidden;
      transform: translateX(-100%);
    }
  }
`;

const Title = styled.h1`
  max-width: 850px;
  font-weight: normal;
  font-size: 26px;
  line-height: 1.5;
  color: ${color('white')};
  
  @media (min-width: 768px) {
    font-size: 33px;
  }
`;

const Label = styled.span`
  display: block;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.5;
  color: ${hex2rgba(color('white'), 0.5)};
  text-transform: uppercase;
`;

const Offset = styled.div`
  transition: all .8s cubic-bezier(0, 0, 0.2, 1) ;
  
  &.collapsed {
    height: 100px !important;
  }
`;

class ArticleHeader extends React.Component {
  state = {
    collapsed: false,
  };

  wrapper = React.createRef();

  offset = React.createRef();

  componentDidMount() {
    const header = this.wrapper.current;
    const offset = this.offset.current;

    header.style.setProperty('height', `${header.offsetHeight}px`);
    offset.style.setProperty('height', `${header.offsetHeight}px`);

    window.addEventListener('scroll', this.handleScroll, false);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { collapsed } = this.state;

    if (nextState.collapsed === collapsed) {
      return false;
    }

    return true;
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, false);
  }

  handleScroll = () => {
    const { pageYOffset } = window;

    if (pageYOffset > 80) {
      this.setState({ collapsed: true });
    } else {
      this.setState({ collapsed: false });
    }
  };

  render() {
    const { collapsed } = this.state;
    const { date, label, logo, title, showLabel } = this.props;

    return (
      <React.Fragment>
        <HeaderWrapper
          className={collapsed && 'collapsed'}
          ref={this.wrapper}
        >
          <Container>
            <div css={{
              marginTop: `${collapsed ? '-25px' : '-20px'}`,
              transition: 'margin-top .3s cubic-bezier(0, 0, 0.2, 1)',
            }}
            >
              <Header disableAnimation>
                <NavHero />
                <Logo src={logo && logo.publicURL} alt="" />
                <MainNav />
              </Header>
            </div>
            <Heading className={collapsed && 'collapsed'}>
              { showLabel && (<Label>{ label || date }</Label>) }
              <Title css={{
                '@media (min-width: 1440px)': {
                  marginTop: !showLabel && '50px',
                },
              }}
              >
                {title}
              </Title>
            </Heading>
          </Container>
        </HeaderWrapper>
        <Offset className={collapsed && 'collapsed'} ref={this.offset} />
      </React.Fragment>
    );
  }
}

export default ArticleHeader;
